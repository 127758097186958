// 封装公用方法对象
  import router from "../../router";

var common = {
    /*-------------------------------------------------------------------------------------------base64图片上传*/
    base64Upload:function({num=1,accept,capture,size,callback,error,onRemoveAResult}){
      if(size > 1024 * 1024 * 300 || !size){
        size = 1024 * 1024 * 300;
      }
      let createFile=() => {
          let e_File = document.createElement("input");
          e_File.setAttribute("type","file");
          e_File.setAttribute("accept",accept || "image/*");
          e_File.setAttribute("multiple","multiple");
          // camera 调用手机拍照设备
          //camcorder 调用手机摄像设备
          //microphone调用手机录音设备
          if(capture){
            e_File.setAttribute("capture",capture);
          }
          return e_File;
        },
        eFile = createFile(),
        nextFile = () => {
          eFile.click();
        },
        aResult = [],
        getAResult = () => {
          return aResult;
        },
        removeAResult = (base64) => {
          aResult.splice(aResult.indexOf(base64),1);
          onRemoveAResult && onRemoveAResult(base64);
        },
        files=[],
        reader = new FileReader(),
        readAsDataURL = (files) => {
          if(files[0]){
             if(files[0].size > size){
              error && error("文件大小超限");
             }else{
              reader.readAsDataURL(files[0]);
             }
          }
        };
      reader.onload=function(){
        let result=this.result;
        let file = files.shift();

        if(aResult.length >= num){
          error && error("最多只能选择"+num+"张图片,清除多出的图片");
        }else if(aResult.indexOf(result)>=0){
          error && error("有重复选项,清除重复的图片");
        }
        // else if(!/data:image/.test(result)){
        //   error && error("非图片格式");
        // }
        else{
          aResult.push(result);
          callback && callback(result);
        }
        readAsDataURL(files);
      }
      eFile.onchange=function(){
        // console.log(this.files[0])
        files=[...this.files];
        readAsDataURL(files);
      }
      return {nextFile,removeAResult,getAResult};
    },
    /*-------------------------------------------------------------------------------------------获取file的blob类型临时路径*/
    getObjectUrl:function (file){
      var url = "";
      if(window.createObjectURL!=undefined){
        url = window.createObjectURL(file);
      } else if(window.URL!=undefined){
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined){
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },
    /*-------------------------------------------------------------------------------------------图片压缩*/
    imgToreduce:function(src,type,callback,scale){
      var width = 0,
      height = 0,
      eImg = new Image(),
      canvas = document.createElement('canvas'),
      context = canvas.getContext('2d');
      eImg.src = src;
      eImg.onload = function(){
          width = this.width * scale;
          height = this.height * scale;
          canvas.width = width;
          canvas.height = height;
          context.clearRect(0, 0, width, height);
          context.drawImage(this, 0, 0,width, height);
          var imgData=canvas.toDataURL(type);
          callback && callback(imgData);
      }
    },
    /*-------------------------------------------------------------------------------------------封装JSONP请求*/
    createJONSP:function (api,parame,callback){
      let search=[];
      for(let key in parame){
        search.push(key + '=' + parame[key]);
      }
      let script=document.createElement('script');
      script.src=api +'?' + search.join("&");

      window[parame.callback]=function (res){
        script.parentNode.removeChild(script);
        callback && callback(res);
        window[parame.callback]=undefined;
      }
      document.body.appendChild(script);
    },

    //-------------------------------------------------------------------------------------------判断设备是pc端还是移动端
    getPAGE:function (){
      if(/(nokia|iphone|android|ipad|motorola|^mot\-|softbank|foma|docomo|kddi|up\.browser|up\.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam\-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte\-|longcos|pantech|gionee|^sie\-|portalmmm|jig\s browser|hiptop|^ucweb|^benq|haier|^lct|opera\s*mobi|opera\*mini|320x320|240x320|176x220)/i.test(window.navigator.userAgent)){
        return "ms";
      }else{
        return "pc";
      }
    },

    //-------------------------------------------------------------------------------------------获取地理定位
    getLocation:function(succeed,error){
      if (navigator.geolocation){
        navigator.geolocation.getCurrentPosition(function(position){
          succeed && succeed(position.coords.latitude,position.coords.longitude);
        },function(error){
          var res = {code:4,msg:'获取位置信息失败！'};
          switch(error.code){
            case error.PERMISSION_DENIED:
              res = {code:1,msg:'已拒绝获取地理位置！信息'};
              break;
            case error.POSITION_UNAVAILABLE:
              res = {code:2,msg:'位置信息是不可用的！'};
              break;
            case error.TIMEOUT:
              res = {code:3,msg:'请求用户地理位置超时！'};
              break;
            case error.UNKNOWN_ERROR:
              res = {code:4,msg:'获取位置信息失败！'};
              break;
          }
          error && error(res);
        });
      } else{
        error && error('该浏览器不支持定位');
      }
    },

    //-------------------------------------------------------------------------------------------适配rem单位
    setRem:function (ratio){
      var oHtml = document.querySelector("html"),
          ratio = ratio || (100 / 375),
          t,
          clientWidth,
          nowRem;
      function rem(){
        clientWidth = document.body.clientWidth;
        nowRem = parseFloat(clientWidth*ratio);
        oHtml.style.fontSize=nowRem+"px";
      }
      rem();
      window.addEventListener("resize",function(){
        clearTimeout(t);
        t = setTimeout(function(){
          rem();
        },100)
      })
    },

    //-------------------------------------------------------------------------------------------更好适配移动端
    viewport:(function () {
      var eMeta = document.all.viewport || document.querySelector("[name='viewport']"),//获取name=viewport的meta元素节点
        InitialWidth, //定义设计图宽度尺寸
        userScalable, //是否禁止用户手动缩放操作
        initialScale, //初始缩放比例
        maximunScale, //定义用户最大缩放比例
        minimumScale, //定义用户最小缩放比例
        W; //当前设备宽度
      if (!eMeta) {
        eMeta = document.createElement('meta');
        eMeta.name = 'viewport';
        document.head.insertBefore(eMeta,document.head.children[0]);
      }
      function setAdaptive(json){
        if(!json) json={};
        InitialWidth=json.width || 750; //获取设计图宽度尺寸，默认750,在iPhone6模拟器上开发最好
        userScalable=json.userScalable || "no"; //默认禁止用户手动缩放
        maximunScale=json.maximunScale || 1.0; //默认当前比例基础上缩放1:1
        minimumScale=json.minimumScale || 1.0; //默认当前比例基础上缩放1:1
        Adaptive();
      }
      function Adaptive() {
        W = window.screen.availWidth; //获取设备浏览器不包含功能栏宽度
        initialScale = W / InitialWidth; //计算初始渲染缩放比例
        //将相关数据配置到meta元素上
        eMeta.content = "width=" + InitialWidth + ", user-scalable="+ userScalable +", initial-scale=" + initialScale +",maximum-scale="+ initialScale*maximunScale +", minimum-scale="+ initialScale*minimumScale;
      }
      setAdaptive();//初始运行模块
      window.addEventListener("resize", function () { //监控浏览器大小变化
        if(W == window.screen.availWidth) return; //每运行一次模块浏览器窗口会监控变动两次，第二次变动不再运行模块，不然会进入死循环
        Adaptive();
      })
      return setAdaptive; //可通过该Api接口动态改变模块内部数据
    }()),
    // Common.viewport(
    //     {
    //         750, //默认750,在iPhone6模拟器上开发最好
    //         userScalable:"yes", //默认禁止用户手动缩放
    //         maximunScale:2, //默认当前比例基础上缩放1:1
    //         minimumScale:0.5 //默认当前比例基础上缩放1:1
    //     }
    // )
    //-------------------------------------------------------------------------------------------BI大屏缩放适配
    selfAdaption:(function () { //BI大屏幕适配
      var el_wrap_box,
          wrap_w,
          wrap_h,
          el_wrap_content,
          wrap_content_w,
          wrap_content_h,
          currentRatio,
          wrapContentRatio,
          is = false,
          setAdaptive = function(optipn){
              if(optipn === false){
                  is = false;
              }else{
                  is = true;
                  el_wrap_box = optipn.el.nodeType == 1 ? optipn.el : document.querySelector(optipn.el);
                  el_wrap_content = el_wrap_box.children[0];
                  wrap_content_w = el_wrap_content.offsetWidth;
                  wrap_content_h = el_wrap_content.offsetHeight;
                  wrapContentRatio = wrap_content_w / wrap_content_h;
                  initialize();
              }
          },
          initialize = function(){
              if(!is) return;
              wrap_w = el_wrap_box.offsetWidth;
              wrap_h = el_wrap_box.offsetHeight;
              currentRatio = wrap_w / wrap_h;
              if(currentRatio > wrapContentRatio){
                  el_wrap_content.style.transform = "scale("+ wrap_h * wrapContentRatio / wrap_content_w +")";
              }else{
                  el_wrap_content.style.transform = "scale("+ wrap_w / wrapContentRatio / wrap_content_h +")";
              }
          };
      window.addEventListener('resize',initialize);
      return setAdaptive;
  })(),


    //-------------------------------------------------------------------------------------------时间戳转换
    timestampToTime:function(timestamp) {
      var weeks = ['天', '一', '二', '三', '四', '五', '六'],
        time = timestamp.toString().length == 10 ? timestamp * 1000 : timestamp, //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        date = new Date(time),
        Y = date.getFullYear(),
        M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
        D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
        W = date.getDay(),
        H = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(),
        I = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(),
        S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return {
        Y,
        M,
        D,
        H,
        I,
        W: weeks[W],
        H_I:H +":"+ I,
        Y_M_D: Y + '-' + M + '-' + D,
        Y_M_D_H_I: Y + '-' + M + '-' + D + " "+ H +":"+ I,
        Y_M_D_H_I_S: Y + '-' + M + '-' + D + " "+ H +":"+ I +":"+ S,
      };
    },

    //-------------------------------------------------------------------------------------------价格小数点后面保留两位数
    setPrice:function (num){
      var price = parseFloat(num) / 100;
      return price.toFixed(2);
    },
    toFixed:function (num,n){
      return parseFloat(num).toFixed(n || 0);
    },

    //-------------------------------------------------------------------------------------------获取创建时间距离当前时间的分钟
    getNumbelDays:function (time){
      let current_time=new Date().valueOf();
      let distance_time=parseInt((current_time - time)/1000/60);
      return distance_time > 999 ? 999 : distance_time;
    },

    //-------------------------------------------------------------------------------------------获取导航地址参数值
    getUrlParam:function (name) {
      var reg = new RegExp('(\\?|&)'+name+'=[^&|#]*','g');
      var s = window.location.href.match(reg);
      return s ? s[0].split('=')[1] : null;
    },
    getUrlParam2:function (name){
      var reg = new RegExp("(?<="+name+"=)[^&]+");
      var search = window.location.search;
      var value = search.match(reg);
      return (value && value[0]) || value;
    },

    //-------------------------------------------------------------------------------------------通过name名获取表单信息返回json格式
    formSerialization:function (obj){
      var aInput=obj.querySelectorAll("[name]"),
        json={},
        i=0,
        input,
        key,
        value,
        type;
      for(;input=aInput[i++];){
        key=input.getAttribute("name");
        value=input.value;
        type=input.getAttribute("type");
        if((type=="radio" || type=="checkbox") && !input.checked){
          continue;
        }
        json[key]=value;
      };
      return json;
    },
    //-------------------------------------------------------------------------------------------通过name名初始化表单信息或元素文本值
    setInitialForm:function (obj,data,fun){
      for(var key in data) {
        var doms = obj.querySelectorAll('"['+key+']"'),
          _value;
        for(var l = 0, dom; dom = doms[l++];) {
          _value=data[key];
          if(_value && dom.value != undefined) {
            if((dom.type == "radio" || dom.type == "checkbox") && dom.value==_value) {
              dom.checked = "checked";
            }else if(dom.tagName == "TEXTAREA") {
              dom.innerText = _value;
            }else{
              dom.value = _value;
            }
          }else{
            dom.innerHTML =_value;
          }
        }
      }
      if(fun) fun(data);
    },

    //-------------------------------------------------------------------------------------------对数组进行随机排序
    shuffle:function (array){
      var i,x,j;
      for(i=array.length;i;i--){
        j = Math.floor(Math.random()*i);
        x = array[j];
        array.push(x);
        array.splice(j,1);
      }
    },

    //-------------------------------------------------------------------------------------------移动端不同方向滑动事件
    slide:function (json){
      var disX= 0,
        disY= 0,
        leftBack=json.leftBack || new Function(),
        rightBack=json.rightBack || new Function(),
        topBack=json.topBack || new Function(),
        bottomBack=json.bottomBack || new Function(),
        callBack=json.callBack || new Function(),
        minMoveX=json.minMoveX || 30,
        minMoveY=json.minMoveY || 20,
        el=json.el || document.body,
        touchmoveFn=null;
      function touchstartFn(event){
        disX=event.touches[0].pageX;
        disY=event.touches[0].pageY;
        touchmoveFn=touchmoveFn2;
        this.addEventListener("touchmove",touchmoveFn1,false);
        this.addEventListener("touchend",touchendFn,false);
      }
      function touchmoveFn1(event){
        touchmoveFn(event,{disX:disX,disY:disY});
      }
      function touchmoveFn2(event){
        var X=event.touches[0].pageX,
          Y=event.touches[0].pageY,
          left=X-disX,
          top=Y-disY;
        if(Math.abs(left)>=minMoveX){
          if(left>0){
            touchmoveFn=rightBack;
          }else{
            touchmoveFn=leftBack;
          }
        }else if(Math.abs(top)>=minMoveY){
          if(top>0){
            touchmoveFn=bottomBack;
          }else{
            touchmoveFn=topBack;
          }
        }
      }
      function touchendFn(event){
        this.removeEventListener("touchmove",touchmoveFn,false);
        this.removeEventListener("touchend",touchendFn,false);
        callBack();
      }
      el.addEventListener("touchstart",touchstartFn,false);
    },

    /*-------------------------------------------------------------------------------------------tab选项*/
    TAB:function ({tabBox,contentBox,showNum=5,activeIndex=0,tabClassName=null,contentClassName=null,type=1,callback}){
      let eTabItems=[...document.querySelector(tabBox).children],
        itemParent=eTabItems[0].parentNode,
        eContentBoxs=[...document.querySelector(contentBox).children],
        tabContentParent=eContentBoxs[0].parentNode,
        itemNum=eTabItems.length,
        itemShowNum=itemNum < showNum ? itemNum : showNum,
        itemWidth=itemParent.offsetWidth/itemShowNum,
        maxNum=Math.floor(itemNum-itemShowNum/2),
        minNum=Math.floor(itemShowNum-itemShowNum/2),
        num=0,
        loading=false,
        tabItemClick=(index) => {
          if(loading && activeIndex==index) return;
          loading=true;
          eTabItems[activeIndex].classList.remove("tab-item-option");
          eTabItems[index].classList.add("tab-item-option");
          num=(index>activeIndex && itemShowNum%2 == 0) ? 1 : 0;
          itemParent.style.left=`${-(index>minNum-num?(index<maxNum?index-minNum+num:maxNum-minNum):0)*itemWidth}px`;
          tabContent(index,activeIndex);
          activeIndex=index;
        },
        tabContent=(index,activeIndex) => {
          let _this=eContentBoxs[index];
          eContentBoxs[activeIndex].classList.remove(contentClassName);
          eContentBoxs[index].classList.add(contentClassName);
          if(type==1){
            eTabItems[activeIndex].classList.remove(tabClassName);
            eTabItems[index].classList.add(tabClassName);
            eContentBoxs[activeIndex].style.display="none";
            eContentBoxs[index].style.display="block";
          }else if(type==2){
            tabContentParent.style.left=`${-(index*100)}%`;
          }
          callback && callback(_this,index,activeIndex);
        };
      eTabItems.forEach((eTab,i) => {
        if(type==2) eTab.style.width=`${itemWidth}px`;
        eTab.onclick = () => {
          tabItemClick(i);
        }
      })
      tabItemClick(activeIndex);
      return tabItemClick;
    },

    /* ----------------------------------------------------------------------------------------- 浮点数运算丢失精度处理*/
    // 支持运算中能出现负数,不兼容微信
    floatOperation:function (sExpression_s){
      // var sExpression_s=sExpression_s, //需要运算表达式的字符串
      //     //"((?<=[*\+-]?)\-?[0-9\.]+)"匹配包括负数的number
      //     patt_s1=/((?<=[*\+-]?)\-?[0-9\.]+)([\*\/\s]+)((?<=[*\+-]?)\-?[0-9\.]+)/, //匹配需要优先运算的“*”和“\”的表达式
      //     patt_s2=/\([^\(\)]+\)/, //匹配需要优先运算的“(****)”括号表达式
      //     sExpression=sExpression_s.match(patt_s1) || sExpression_s.match(patt_s2); //获取需要优先运算的表达式
      //     sExpression=sExpression?sExpression[0]:""; //获取需要优先运算的表达式
      //     if(sExpression){
      //         // console.log(sExpression_s,sExpression);
      //         sExpression_s=sExpression_s.replace(sExpression,operation(sExpression))//获取需要优先运算的表达式所求的值，并与表达式替换
      //         return floatOperation(sExpression_s); //将替换后的表达式再次过滤，所求得的值递归方式返回最终的表达式
      //         // console.log(sExpression_s);
      //     }
      // return  operation(sExpression_s); //返回最终表达式所求出的值
      // //求取子表达式的运算结果值
      // function operation(sExpression){
      //     var sExpression =sExpression , //需要运算表达式的字符串
      //         patt=/((?<=[*\+-]?)\-?[0-9\.]+)/g, //匹配全局的number数据
      //         aNum=sExpression.match(patt), //获取全局number数据
      //         aExpression=sExpression.match(/((?<=[*\+-]?)\-?[0-9\.]+)|([\+\-\/\*])/g), //分割表达式
      //         operator=aExpression[1],//获取运算符
      //         floatLength=[], //记录对应number数据的浮点数长度
      //         maxFloatLength=0, //定义最大浮点数长度
      //         ratio=1; //最后换算比例

      //         aNum.forEach(function(num,i){
      //             let aFloat=num.split(".")[1] || [];//获取每个number后面的浮点数
      //             floatLength.push(aFloat.length); //记录每一个浮点数的长度
      //         })
      //         maxFloatLength=Math.max.apply(null,floatLength); //获取最大长度对应的值

      //         aNum.forEach(function(num,i){
      //             let aParse=num.split(".");//获取对应number整数和后面的浮点数
      //             // let float=aParse[1] || "";//获取对应number后面的浮点数
      //             // let aFloat=float.split(""); //将浮点数分割为数组
      //             //     aFloat.length=maxFloatLength-aFloat.length;
      //             //     aFloat.fill("0");
      //             //     aParse.push(...aFloat);
      //             aParse[1]=(aParse[1] || "").padEnd(maxFloatLength,"0");
      //             aExpression[aExpression.indexOf(num)]=parseFloat(aParse.join("")); //替换表达式的number数据，转换成相同倍数比例的整数
      //         })
      //         ratio=Math.pow(10,maxFloatLength);
      //         if(operator=="*"){
      //             ratio=Math.pow(ratio,2);
      //         }else if(operator=="/"){
      //             ratio=1;
      //         }
      //         // console.log(aExpression,maxFloatLength,ratio);
      //     return eval("("+aExpression.join("")+")")/ratio;
      // }
    },
    // console.log(floatOperation("-4+((2+52.06)/-200)*(-100/0.44)*-100"),'-----------1-------',-4+((2+52.06)/-200)*(-100/0.44)*-100);
    // console.log(floatOperation("-0.1+-0.2"),"----------2--------",-0.1+-0.2);
    // console.log(floatOperation("5204.06*-100"),"----------2--------",5204.06*-100);

    //------------------------------------------------------------------------------------------模拟键盘输入
    keyInput:function (val,keyVal){
      val = val.toString();
      if(keyVal == -1){
        val = val.substring(0,val.length-1);
      }else{
        val += keyVal.toString();
      }
      return val;
    },

    /*-------------------------------------------------------------------------------------------获取扫码枪扫码编码*/
    componentDidMount:function (callback) {
      var code = "";
      var lastTime,nextTime;
      var lastCode,nextCode;
      window.onkeypress=function(e){
        nextCode = e.which;
        nextTime = new Date().getTime();

        if(lastCode != null && lastTime != null && nextTime - lastTime <= 30) {
          code += String.fromCharCode(lastCode);
        } else if(lastCode != null && lastTime != null && nextTime - lastTime > 100){
          code = "";
        }

        lastCode = nextCode;
        lastTime = nextTime;
        if(e.which == 13){
          callback && callback(code);
          code = "";
        }
      }
      window.focus();
      window.onblur=function(){
        setTimeout(function(){
          window.focus();
        },100)
      }
    },

    /*--------------------------------------------------------------------------------------------截图*/
    DomToImg:function (dom,callback){
      var width,
        height,
        cloneDom,
        canvas = document.createElement('canvas'),
        context = canvas.getContext('2d'),
        img = new Image();
      var exports_v = {
        dom2Svg:function(){
          img.onload=function(){
            context.clearRect(0, 0, width, height);
            context.drawImage(img, 0, 0);
            var imgData = canvas.toDataURL("image/jpeg");
            // console.log(imgData);
            callback && callback(imgData);
          }
          var data = `
                  <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
                      <foreignObject x="0" y="0" width="100%" height="100%">
                          <div xmlns="http://www.w3.org/1999/xhtml">
                              ${cloneDom.outerHTML}                            
                          </div>
                      </foreignObject>
                  </svg>
              `;
          img.src='data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(data)));
        },
        setDom:function(dom){
          width = dom.offsetWidth,
          height = dom.offsetHeight,
          cloneDom = dom.cloneNode(true);
          canvas.width=width;
          canvas.height=height;
        }
      }
      exports_v.setDom(dom);
      // cloneDom.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
      exports_v.dom2Svg();
    },

    /*-------------------------------------------------------------------------------------------监控网络状态*/
    onConnectionChange: function (callback){
      function onConnectionChange(){
        const { rtt, downlink, effectiveType, saveData } = navigator.connection;
        const onLine = navigator.onLine;
        // alert(`有效网络连接类型: ${effectiveType}---`+`估算的下行速度/带宽: ${downlink}Mb/s---`+`估算的往返时间: ${rtt}ms----`+saveData)
        // console.log(`有效网络连接类型: ${effectiveType}`);
        // console.log(`估算的下行速度/带宽: ${downlink}Mb/s`);
        // console.log(`估算的往返时间: ${rtt}ms`);
        // console.log(`估算的往返时间: ${saveData}`);
        // console.log(navigator);
        if(rtt > 0 || onLine){
          // $msg(`有效网络连接类型: ${effectiveType}---`+`估算的下行速度/带宽: ${downlink}Mb/s---`+`估算的往返时间: ${rtt}ms----`+`估算的往返时间: ${rtt}ms`)
          callback && callback(1);
        }else{
          callback && callback(0);
        }
      }
      if(navigator.connection){
        onConnectionChange();
        navigator.connection.addEventListener('change', onConnectionChange);
      }else{
        window.ononline=function(){
          callback && callback(1);
        };
        window.onoffline=function(){
          callback && callback(0);
        };
      }
    },

    /*-------------------------------------------------------------------------------------------滚动加载*/
    scrollLoad: function (json) {
      var dom = json.el,
        dir_bottom = json.dir_bottom || 30,
        callback = json.callback,
        is_trigger = false,
        e_div = document.createElement('div');
      e_div.innerHTML = '<div style="width:100%; height:50px; color:#666; line-height:50px; text-align:center;">...加载中</div>';

      dom.onscroll = function () {
        if (is_trigger) return;
        var s_t = this.scrollTop,
          s_h = this.scrollHeight,
          v_h = this.offsetHeight;

        if (s_t + v_h + dir_bottom >= s_h && s_t != 0) {
          is_trigger = true;
          dom.appendChild(e_div);
          callback && callback(is_trigger);
        }
      };

      return {
        continue: function _continue(is_continue) {
          e_div && e_div.parentNode && e_div.parentNode.removeChild(e_div);
          if (is_continue != false) {
            is_trigger = false;
          }
        },
        callback:callback,
      };
    },

    /*-------------------------------------------------------------------------------------------分页*/
    Paging: (function(){
      function Pagination(el,option){
        this.el = el;
        option = option ? option : {};
        this.onchange = option.onchange;
        this.el.onclick = this.click.bind(this);
        this.el.onmousedown = this.mousedown.bind(this);
        this.el.onmouseup = this.mouseup.bind(this);
        this.el.onmouseover = this.mouseover.bind(this);
        this.el.onmouseout = this.mouseout.bind(this);
      }
      Pagination.prototype = {
        constructor:Pagination,
        click:function(event){
          // console.log(event.target.getAttribute('data-pageSize'));
          var pageNumber = event.target.getAttribute('data-pageNumber'),
            pages = {};
          if(pageNumber && pageNumber != this.pageNumber){
            pages = {
              pageCount:this.pageCount,
              pageNumber:pageNumber,
              totalPage:this.totalPage,
            };
            this.init(pages);
            this.onchange && this.onchange(pages);
          }
        },
        mousedown:function(event){
          var pageNumber = event.target.getAttribute('data-pageNumber');
          if(pageNumber){
            // event.target.setAttribute('data-toggle',event.target.getAttribute('data-hover'));
            event.target.style.opacity = .6;
          }
        },
        mouseup:function(event){
          var pageNumber = event.target.getAttribute('data-pageNumber');
          if(pageNumber){
            event.target.style.opacity = event.target.getAttribute('data-toggle');
          }
        },
        mouseover:function(event){
          var pageNumber = event.target.getAttribute('data-pageNumber');
          if(pageNumber){
            event.target.setAttribute('data-toggle',event.target.style.opacity);
            event.target.style.opacity = .8;
          }
        },
        mouseout:function(event){
          var pageNumber = event.target.getAttribute('data-pageNumber');
          if(pageNumber){
            event.target.style.opacity = event.target.getAttribute('data-toggle');
          }
        },
        init:function (pages){
          this.pageCount = parseInt(pages.pageCount || 10);
          this.pageNumber = parseInt(pages.pageNumber || 1);
          this.totalPage = parseInt(pages.totalPage) || 1;
          this.minNumber = 1;
          this.maxNumber = this.totalPage;
          this.el.innerHTML = this.render();
        },
        render:function (){
          var pageCount = this.pageCount,
            pageNumber = this.pageNumber,
            totalPage = this.totalPage,
            minNumber = this.minNumber,
            maxNumber = this.maxNumber,
            center_num = ((pageCount < totalPage ? pageCount : totalPage)  / 2) - 0.5,
            min = pageNumber - center_num,
            max = pageNumber + center_num,
            i = min < minNumber ? minNumber : Math.floor(min + (max > maxNumber ? maxNumber : max) - max),
            l = max > maxNumber ? maxNumber : (max + i - min),
            s_html = '';
          return '<div class="" style="font-size:14px; color:#666; text-align:center; user-select: none;">'+
            '<div class="first" data-pageNumber="'+ minNumber +'" style="display: inline-block; vertical-align: middle;  border:1px solid #409eff; border-radius: 4px; padding:6px 14px; background:#409eff; color:#fff; '+ (minNumber >= pageNumber ? 'opacity:.6;' : '') +'">首页</div>'+
            '<div class="prev" data-pageNumber="'+ (pageNumber > minNumber ? pageNumber - 1 : minNumber) +'" style="display: inline-block; vertical-align: middle; margin-left:4px; border:1px solid #409eff; border-radius: 4px; padding:6px 14px; background:#409eff; color:#fff; '+ (minNumber >= pageNumber ? 'opacity:.6;' : '') +'">上一页</div>'+
            '<ul class="pages" style="list-style-type:none; display: inline-block; margin:0 4px; vertical-align: middle; padding:0;">'
            +
            (function(){
              for(;i<=l;i++){
                s_html +=  '<li class="pages-item" data-pageNumber="'+ i +'" style="list-style-type:none; display: inline-block; vertical-align: middle; margin-left:4px; border-radius: 4px; min-width:31px; height:31px; line-height:30px; '+ (i == pageNumber ? 'background:#409eff; color:#fff; border:1px solid #409eff;' : 'border:1px solid #666;') +'">'+ i +'</li>'
              }
              return s_html;
            }())
            +
            '</ul>'+
            '<div class="next" data-pageNumber="'+ (pageNumber < maxNumber ? pageNumber + 1: maxNumber) +'" style="display: inline-block; vertical-align: middle; margin-left:4px; border:1px solid #409eff; border-radius: 4px; padding:6px 14px; background:#409eff; color:#fff; '+ (maxNumber <= pageNumber ? 'opacity:.6;' : '') +'">下一页</div>'+
            '<div class="last" data-pageNumber="'+ maxNumber +'" style="display: inline-block; vertical-align: middle; margin-left:4px; border:1px solid #409eff; border-radius: 4px; padding:6px 14px; background:#409eff; color:#fff; '+ (maxNumber <= pageNumber ? 'opacity:.6;' : '') +'">尾页</div>'+
            '</div>'
        }
      };
      return Pagination;
    }()),
    // new Paging(document.querySelector('.pagination')).init({
    //     pageCount:5,
    //     pageNumber:2,
    //     totalPage:20,
    // });

    /*-------------------------------------------------------------------------------------------处理苹果机上拉触顶和下拉触底还能拉动,导致容器的scroll滚动条触发延迟问题*/
    dealIosSlideScroll:function(dataset){
      if(!/ipad|iphone/i.test(window.navigator.userAgent)) return;
      var e_box = document.body.children[0],
          e_scroll_s = [],
          startY,
          checkTriggerTop = function (dom){
            return dom.scrollTop == 0;
          },
          checkTriggerBottom = function (dom){
            // console.log("bottom",scrollTop == scrollTop + clientHeight == scrollHeight);
            return dom.scrollTop + dom.clientHeight == dom.scrollHeight;
          };
      e_box.ontouchstart = function(e){
        var dom = e.target;
        startY = e.touches[0].pageY;
        e_scroll_s = [];
        if(dataset){
          do{
            if(dom.dataset[dataset] && dom.clientHeight != dom.scrollHeight){
              e_scroll_s.push(dom);
            }
            dom = dom.parentNode;
          }
          while(dom != document.body);
        }else{
          // console.log(dom);
          do{
            if(dom.clienttHeight != dom.scrollHeight){
              e_scroll_s.push(dom);
            }
            dom = dom.parentNode;
          }
          while(dom != document.body);
        }
        // console.log(e_scroll_s)
      }
      e_box.ontouchmove = function(e){
        var pageY = e.touches[0].pageY;
        if((startY < pageY && e_scroll_s.every(checkTriggerTop)) || (startY > pageY && e_scroll_s.every(checkTriggerBottom))){
          e.preventDefault();
        }
        startY = pageY;
      };
    }
  };

export default {
  install (Vue, options) {
    Vue.prototype.$common = common;

    Vue.prototype.to = function(routes,outer_url){
      console.log(routes,outer_url)
      if(outer_url){
          window.open(outer_url);
      }else if(routes || routes.path){
          router.push(routes);
      }
    }

    Vue.prototype.imgLoadingError = function(e,src){
      e.target.src = src;
    }
    // 添加全局方法或者属性
    //Vue.myGlobMethod = function () {};
    // 添加全局指令
    //Vue.directive();
    // 添加混入
    //Vue.mixin();
    // 添加实例方法
    //Vue.prototype.$xxx = function () {};
    // 注册全局组件
    //Vue.component()
    //定义全局过滤器
    Vue.filter('secondFormDate', function (val) {
      let s_l = parseInt(val) | 0,
      i = parseInt(s_l / 60),
      s = s_l % 60;
      return `${i < 10 ? '0' + i.toString() : i}:${s < 10 ? '0' + s.toString() : s}`;
    })
  },
  $Common:common,
};
