import Vue from 'vue'
//view ui
import 'view-design/dist/styles/iview.css';

import {Message} from 'view-design';
Message.config({
  top: 100,
  duration: 3
});
Vue.prototype.$Msg = Message;

import '@/assets/css/style.css';

import Echarts from "@/Echarts" //百度echarts图表
Vue.use(Echarts);

import AMap from "@/AMap" //高德地图
Vue.use(AMap);

//监听vue运行报错
// Vue.config.errorHandler = function (err) {
//   console.log(err.toString())
// };

// 公共方法
import Common from "@/assets/js/common"
Vue.use(Common);

// ajax封装
import Http from "@/http"
Vue.use(Http);

import router from './router'
router.beforeEach((to, from, next) => {
    // alert(window.screen.availWidth)
    Vue.prototype.$common.viewport(
      {
          width:window.screen.availWidth * 2 < 900 ? 750 : (window.screen.availWidth * 2 > 1400 ? 1400 : window.screen.availWidth * 2), //默认750,在iPhone6模拟器上开发最好
          userScalable:"no", //默认禁止用户手动缩放
          maximunScale:2, //默认当前比例基础上缩放1:1
          minimumScale:0.5 //默认当前比例基础上缩放1:1
      }
    )
    vueRoot && (vueRoot.$off('update'), vueRoot.$Loading.isLoading = true, vueRoot.$Loading.num = 0);
    document.title = to.meta.title ? `${to.meta.title}` : '朗数科技';
    Vue.prototype.$common.selfAdaption(false);
    Message.destroy();
    next();
});



Vue.config.productionTip = false;
import App from './App.vue'
import store from "@/store"
var vueRoot = new Vue({
  router,
  store,
  render: h => h(App),
  created(){
    setInterval(() => {
        if(this.$Loading.isLoading){
            this.$Loading.isLoading = false;
        }else{
            this.$emit('update');
        }
    },1000*60*10)
  },
  mounted(){
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
