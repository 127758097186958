const save = {
  t:null,
  time:100,
  set(key,value){
    if(!value){
      window.localStorage.removeItem(key);
      return;
    }
    clearTimeout(this.t);
    this.t = setTimeout(function(){
      window.localStorage.setItem(key,JSON.stringify(value));
    },this.time);
  }
};
//阅读记录模块
const SAVE_USER_INFOR = 'SAVE_USER_INFOR'  //保存用户信息
const DELETE_USER_INFOR = 'REMOVE_USER_INFOR'  //删除用户信息
const SAVE_ACCOUNT_PASSWORD = 'SAVE_ACCOUNT_PASSWORD'  //记住密码 保存账号密码
const DELETE_ACCOUNT_PASSWORD = 'DELETE_ACCOUNT_PASSWORD'  //删除保存的账号密码

const UserInfo = JSON.parse(window.sessionStorage.getItem('userInfo') || '{}');
const Account_Password = JSON.parse(window.localStorage.getItem('account_password') || '{}');
export default {
  namespaced: true,
  state:{
    userInfo:UserInfo,
    account_password:Account_Password,
  },
  mutations:{
    [SAVE_USER_INFOR](state,payload){ //保存用户信息
      state.userInfo = payload;
      console.log(payload)
      window.sessionStorage.setItem('userInfo',JSON.stringify(state.userInfo)); //保存数据
    },
    [DELETE_USER_INFOR](state,payload){ //删除用户信息
      state.userInfo = {};
      window.sessionStorage.removeItem('userInfo');
    },
    [SAVE_ACCOUNT_PASSWORD](state,payload){  //记住密码 保存账号密码
      let {account,password} = payload;
      state.account_password = {
        account,
        password
      };
      save.set("account_password",state.account_password); //保存数据
    },
    [DELETE_ACCOUNT_PASSWORD](state,payload){  //删除保存的账号密码
      let {account} = payload;
      state.account_password.account = account;
      state.account_password.password = "";
      save.set("account_password",state.account_password); //保存数据
    },
  },
  getters:{

  },
};
