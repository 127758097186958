import AMapLoader from "@amap/amap-jsapi-loader";
const getAMap = AMapLoader.load({
    key: "5f35b55a7b0e962f0c577c88d6b23a5c", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: ["AMap.DistrictSearch"] //插件列表
})
export default{
    install(Vue){
        Vue.prototype.$GetAMap = getAMap;
    },
    $GetAMap:getAMap
 }