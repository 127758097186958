<template>
  <div id="app">
    <!-- <self-adaption mode="auto-x"> -->
       <router-view /> 
    <!-- </self-adaption> -->
  </div>
</template>

<script>
    import {
        selfAdaption
    } from "@/components"
    export default {
        name: '',
        components: {
            selfAdaption,
        },
        mixins:[
        ],
        props: [],
        data() {
            return {
                isLoad:false
            }
        },
        computed: { //计算属性

        },
        watch: {  //监听器

        },
        methods: {
            
        },
        created(){  
        }, //组件实例创建完成，属性已绑定，但DOM还未生成，属性还不存在
        mounted(){        

        }, //模板编译/挂载之后(不保证组件已在document中)
        beforeRouteLeave(to, from, next) {

            next();
        },
//        beforeCreate(){}, //组件实例刚被创建，组件属性计算之前，如data属性等
//        beforeMount(){}, //模板编译/挂载之前
//        beforeUpdate(){}, //组件更新之前
//        updated(){}, //组件更新之后
//        activated(){}, //for keep-alive,组件被激活时调用
//        deactivated(){}, //for keep-alive,组件被激活时调用
//        beforeDestory(){}, //组件销毁前调用
//        destoryed(){}, //组件销毁后调用
    }
</script>

<style>
#app{
    width:inherit;
    height:inherit;
    /* background:url(./assets/img/bg.png) no-repeat center; */
    background-color:#fff;
    background-size:100% 100%;
}
.rich_text img{
  max-width:100%;
}

/*.ivu-input[disabled], fieldset[disabled] .ivu-input{*/
/*    background-color: #fff!important;*/
/*    opacity: 1;*/
/*    cursor: not-allowed;*/
/*    color: #777;*/
/*}*/
.wrap-box .ivu-select-single .ivu-select-selection,
.wrap-box .ivu-select-single .ivu-select-selection .ivu-select-placeholder,
.wrap-box .ivu-select-single .ivu-select-selection .ivu-select-selected-value{
    height: 46px;
    line-height:46px;
    font-size:18px;
    color:#fff;
}
.wrap-box .ivu-select-selection,
.wrap-box .ivu-select-dropdown{
    border:1px solid #1f52aa;
    background-color:rgba(14,32,60,.5);
    border-radius:0;
}
.wrap-box .ivu-select-arrow{
    font-size:30px;
    color:#fff;
}
.wrap-box .ivu-select-dropdown{
    top:46px!important;
    left:0!important;
}
.wrap-box .ivu-select-dropdown .ivu-select-item{
    color:#fff;
    font-size:16px!important;
}
.wrap-box .ivu-select-dropdown .ivu-select-item-focus,
.wrap-box .ivu-select-dropdown .ivu-select-item:hover{
    background-color:#205cba;
}
/*.ivu-input-search{*/
/*    background-color:#ED2B24!important;*/
/*    border-color:#ED2B24!important;*/
/*}*/
/*.ivu-input-search:hover{*/
/*    background-color:#ED2B24!important;*/
/*    border-color:#ED2B24!important;*/
/*    opacity: .9;*/
/*}*/
.ivu-table th{
    background-color:#E2F4FF;
}
/*自定义滚动条*/
/* *::-webkit-scrollbar{
    width:6px;
    height:6px;
}
*::-webkit-scrollbar-track{
    border-radius:4px;
}
*:hover::-webkit-scrollbar-track{
    background:#182238;
}

*:hover::-webkit-scrollbar-thumb{
    background:rgba(94,227,253,.4);
    border-radius:6px;
}
*::-webkit-scrollbar-thumb:hover{
    background:#1281ca;
    border-radius:4px;
} */
</style>
