import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component:() => import('@/views/home'),
    meta:{title:"首页"},
  },
  {
    path: '/previewH5',
    name: 'previewH5',
    component:() => import('@/views/previewH5'),
    meta:{title:"预览H5"},
  },
  {
    path: '/scheme/sqyl',
    name: 'sqly_info',
    component:() => import('@/views/scheme/sqyl'),
    meta:{title:"社区养老解决方案"},
  },
  {
    path: '/scheme/2',
    name: 'scheme_2',
    component:() => import('@/views/scheme/2'),
    meta:{title:"居家养老解决方案"},
  },
  {
    path: '/scheme/3',
    name: 'scheme_3',
    component:() => import('@/views/scheme/3'),
    meta:{title:"机构养老解决方案"},
  },
  {
    path: '/scheme/4',
    name: 'scheme_4',
    component:() => import('@/views/scheme/4'),
    meta:{title:"养老评估解决方案"},
  },
  {
    path: '/enterprise/application',
    name: 'enterpriseApplication',
    component:() => import('@/views/enterpriseApplication/1'),
    meta:{title:"企业应用"},
  },
  {
    path: '/product/1',
    name: 'product_1',
    component:() => import('@/views/product/1'),
    meta:{title:"智慧养老平台"},
  },
  {
    path: '/product/2',
    name: 'product_2',
    component:() => import('@/views/product/2'),
    meta:{title:"智能硬件支持"},
  },
  {
    path: '/product/3',
    name: 'product_3',
    component:() => import('@/views/product/3'),
    meta:{title:"移动应用平台"},
  },
  {
    path: '/product/4',
    name: 'product_4',
    component:() => import('@/views/product/4'),
    meta:{title:"可视化大屏"},
  },
  {
    path: '/serve/1',
    name: 'serve_1',
    component:() => import('@/views/serve/1'),
    meta:{title:"24小时呼叫中心"},
  },
  {
    path: '/serve/2',
    name: 'serve_2',
    component:() => import('@/views/serve/2'),
    meta:{title:"辅具租赁"},
  },
  {
    path: '/serve/3',
    name: 'serve_3',
    component:() => import('@/views/serve/3'),
    meta:{title:"适老化改造"},
  },
  {
    path: '/aboutUs/1',
    name: 'aboutUs_1',
    component:() => import('@/views/aboutUs/1'),
    meta:{title:"公司介绍"},
  },
  {
    path: '/aboutUs/2',
    name: 'aboutUs_2',
    component:() => import('@/views/aboutUs/2'),
    meta:{title:"加入我们"},
  },
  {
    path: '/aboutUs/3',
    name: 'aboutUs_3',
    component:() => import('@/views/aboutUs/3'),
    meta:{title:"联系我们"},
  },
  {
    path: '/aboutUs/4',
    name: 'aboutUs_4',
    component:() => import('@/views/aboutUs/4'),
    meta:{title:"新闻"},
  },
  {
    path: '/aboutUs/detail',
    name: 'aboutUs_detail',
    component:() => import('@/views/aboutUs/detail'),
    meta:{title:"新闻详情"},
  },
  {
    path: '/infos',
    name: 'infos',
    component:() => import('@/views/infos'),
    meta:{title:"智慧养老综合管理平台"},
  },
];

// const router = new VueRouter({
//   // mode: 'history',
//   // base: process.env.BASE_URL,
//   routes,
//   scrollBehavior (to, from, savedPosition) {
//     return savedPosition;
//   },
// })

const router = new VueRouter({
  mode: 'history',
  // base: "/dist",
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || {y:0, x:0};
  },
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
    return VueRouterReplace.call(this, to).catch(err => err)
}

export default router
