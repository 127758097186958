import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import LOGIN from './login' //登录模块
export default new Vuex.Store({
  namespaced: true,
  state: {
    a:{b:4}
  },
  mutations: {
  },
  actions: {

  },
  modules: {
    LOGIN, //登录模块
  }
})
