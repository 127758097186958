<template>
    <div class="self-adaption-wrap">
        <div class="self-adaption-box" :style="contentStyle" ref="el_box">
            <slot></slot>
        </div>
    </div>
</template>

<script>
const debounce = (func, delay=300) => {
    let timeout=null;
    return function() {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, arguments)
        }, delay)
    }
}
    export default {
        name: 'pages',
        components: {

        },
        props: {
            width:[String, Number],
            height:[String, Number],
            mode:{
                type:String,
                default:"auto",
            }
        },
        data() {
            return {
               contentStyle:{},
            }
        },
        computed: { //计算属性

        },
        watch: {  //监听器
            width(){
                this.init();
            },
            mode(){
                this.init();
            }
        },
        methods: {
            auto(){
                const {
                    $refs:{
                        el_box:el_wrap_content,
                        el_box:{
                            parentNode:{
                                offsetWidth:wrap_w,
                                offsetHeight:wrap_h,
                            }
                        },
                    },
                    width:wrap_content_w = el_wrap_content.offsetWidth,
                    height:wrap_content_h = el_wrap_content.offsetHeight,
                } = this;
                let wrapContentRatio = wrap_content_w / wrap_content_h;
                let currentRatio = wrap_w / wrap_h;
                let scaleRatio = currentRatio > wrapContentRatio ? (wrap_h * wrapContentRatio / wrap_content_w)  : (wrap_w / wrapContentRatio / wrap_content_h);
                let translateX = (wrap_w / scaleRatio - wrap_w) / 2 * -1;
                let translateY = (wrap_h / scaleRatio - wrap_h) / 2 * -1;
                this.contentStyle = {
                    width: wrap_w / scaleRatio + "px",
                    height: wrap_h / scaleRatio + "px",
                    transform: `translateX(${translateX.toFixed(4)}px) translateY(${translateY.toFixed(4)}px) scale(${scaleRatio.toFixed(4)})`
                }
            },
            autoX(){
                const {
                    $refs:{
                        el_box:el_wrap_content,
                        el_box:{
                            parentNode:{
                                offsetWidth:wrap_w,
                                offsetHeight:wrap_h
                            }
                        },
                    },
                    width:wrap_content_w = el_wrap_content.offsetWidth,
                } = this;
                let scaleRatio = wrap_w / wrap_content_w;
                let translateX = wrap_content_w / 2 * -1 + wrap_w / 2;
                let translateY = (wrap_h / scaleRatio - wrap_h) / 2 * -1;
                this.contentStyle = {
                    width: wrap_content_w + "px",
                    height: wrap_h / scaleRatio + "px",
                    transform: `translateX(${translateX.toFixed(4)}px) translateY(${translateY.toFixed(4)}px) scale(${scaleRatio.toFixed(4)})`
                }       
            },
            autoXY(){
                const {
                    $refs:{
                        el_box:el_wrap_content,
                        el_box:{
                            parentNode:{
                                offsetWidth:wrap_w,
                                offsetHeight:wrap_h,
                            }
                        },
                    },
                    width:wrap_content_w = el_wrap_content.offsetWidth,
                    height:wrap_content_h = el_wrap_content.offsetHeight,
                } = this;
                let wrapContentRatio = wrap_content_w / wrap_content_h;
                let currentRatio = wrap_w / wrap_h;
                let scaleRatio = currentRatio > wrapContentRatio ? (wrap_h * wrapContentRatio / wrap_content_w)  : (wrap_w / wrapContentRatio / wrap_content_h);
                let translateX = wrap_content_w / 2 * -1 + wrap_w / 2;
                let translateY = wrap_content_h / 2 * -1 + wrap_h / 2;
                this.contentStyle = {
                    width: wrap_content_w+ "px",
                    height: wrap_content_h + "px",
                    transform: `translateX(${translateX.toFixed(4)}px) translateY(${translateY.toFixed(4)}px) scale(${scaleRatio.toFixed(4)})`
                }        
            },
            init(){
                let {
                    mode
                } = this;
                switch(mode){
                    case "auto":
                        this.auto();
                        let bindResizeAuto = debounce(() => {
                            if(this.$refs.el_box){
                                this.auto();
                                this.$emit("resize");
                            }else{
                                window.removeEventListener("resize",bindResizeAuto);
                            }
                        },100);
                        window.addEventListener("resize",bindResizeAuto);                    
                    break;
                    case "auto-x":
                        this.autoX();
                        // let reload = debounce(() => {
                        //     window.location.reload();
                        // },1500);
                        let bindResizeAutoX = debounce(() => {
                            if(this.$refs.el_box){
                                this.autoX();
                                this.$emit("resize");
                            }else{
                                window.removeEventListener("resize",bindResizeAutoX);
                            }
                        },100);     
                        window.addEventListener("resize",bindResizeAutoX);                
                    break;
                    case "auto-x-y":
                        this.autoXY();
                        // let reload = debounce(() => {
                        //     window.location.reload();
                        // },1500);
                        let bindResizeAutoXY = debounce(() => {
                            if(this.$refs.el_box){
                                this.autoXY();
                                this.$emit("resize");
                            }else{
                                window.removeEventListener("resize",bindResizeAutoXY);
                            }
                        },100);     
                        window.addEventListener("resize",bindResizeAutoXY);                
                    break;
                };
            }
        },
        created() {

        }, //组件实例创建完成，属性已绑定，但DOM还未生成，属性还不存在
        mounted(){
            this.init();
            this.$emit('load');
        }, //模板编译/挂载之后(不保证组件已在document中)
//        beforeCreate(){}, //组件实例刚被创建，组件属性计算之前，如data属性等
//        created(){}, //组件实例创建完成，属性已绑定，但DOM还未生成，属性还不存在
//        beforeMount(){}, //模板编译/挂载之前
//        beforeUpdate(){}, //组件更新之前
//        updated(){}, //组件更新之后
//        activated(){}, //for keep-alive,组件被激活时调用
//        deactivated(){}, //for keep-alive,组件被激活时调用
//        beforeDestory(){}, //组件销毁前调用
//        destoryed(){}, //组件销毁后调用
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.self-adaption-wrap{
    width:100%;
    height:100%;
    overflow:hidden;
}
.self-adaption-box{
    overflow:auto;
    -webkit-overflow-scrolling: touch;
    display:inline-block;
}
</style>
