//ajax
import Axios from "axios"

import {Message} from 'view-design';
const $Message = Message;
// Message.loading({
//     content: 'Loading...',
//     duration: 0
// })
// import $router from '@/router';
import Store from '@/store'

const {api:$API, baseURL} = require('./api');
const $CACHE = require('./cache');
// import $wx from "@/common/wx/wx-sdk"
const $Msg = {
    msg:null,
	showLoading(...arg){
        this.msg = $Message.loading(...arg);
	},
	hideLoading(...arg){
        let {msg} = this;
        msg && msg();
        this.msg = null;
	},
	showModal(...arg){
		// uni.showModal(...arg);
	},
    warning(...arg){
        $Message.warning(...arg);
	},
    error(...arg){
        $Message.error(...arg);
    },
};

const $Loading = {
   t:null,
   num:0,
   isLoading:true,
   show(){
       if(this.isLoading == false) return;
       if(0 == this.num){
            this.t = setTimeout(() => {
                $Msg.showLoading({
                   content: 'Loading...',
                   duration: 0
                })
            },500)
       }
       this.num++;
   },
   hide(){
       this.num--;
       if(0 == this.num){
           clearTimeout(this.t);
           $Msg.hideLoading();
       }
   },
};
const http = function(config) {
   config = JSON.parse(JSON.stringify(config));
   const {token} = Store.state.LOGIN;
   let {
	   cache = false,
	   header = {},
   } = config;
   if({}.toString.call(config.url).slice(8,-1) == "Array"){
	   let urls = config.url;
	   let urlType = urls.shift();
	   config.url = (config.baseURL || baseURL) + ($API[urlType] || `/${urlType.replace(/^\/*/,'')}/`) + urls.join('/');
   }else{
	   config.url = (config.baseURL || baseURL) + ($API[config.url] || `/${config.url.replace(/^\/*/,'')}`);
   }
   if(token) header.token = token;
   config.header = header;
   return new Promise((resolve, reject) => {
	  if(cache == true && $CACHE[config.url]){
		   resolve($CACHE[config.url]);
		   return;
	  }else if($CACHE[config.url]){
		  delete $CACHE[config.url];
	  }
      $Loading.show();
      Axios.request({
         ...config,
      }).then((res) => {
         $Loading.hide();
         let {code,msg="服务器异常",data} = res.data;
         if(code == 200){
            if(cache == true) $CACHE[config.url] = data;
            resolve(data);
         }else{
            $Msg.warning({
                background: true,
                content: msg,
            });
            reject(res.data);
         }
      }).catch((err) => {
          $Loading.hide();
          $Msg.error({
              background: true,
              content: '请求失败',
          });
          reject(err);
      })
   })
};
http.get = function(url,data,config={}){
   config.method = "GET";
   config.url = config.url || url;
   config.params = data;
   return http(config);
};
http.post = function(url,data,config={}){
   config.method = "POST";
   config.url = config.url || url;
   config.data = data;
   return http(config);
};
export default{
   install(Vue){
	   Vue.prototype.$http = http;
	   Vue.prototype.$Msg = $Msg;
       Vue.prototype.$Loading = $Loading;
   },
   $http:http,
}